import { Component } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon("ariaDialogSend",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-dialog-send.svg"),
    );
    this.matIconRegistry.addSvgIcon("ariaArrow",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/keyboard-arrow.svg"),
    );
    this.matIconRegistry.addSvgIcon("ariaConfirm",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-dialog-confirm.svg"),
    );
    this.matIconRegistry.addSvgIcon("ariaInfo",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-dialog-info.svg"),
    );
    this.matIconRegistry.addSvgIcon("selectionStep",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/selectionStep.svg"),
    );

    this.matIconRegistry.addSvgIcon("ticketStep",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ticketStep.svg"),
    );

    this.matIconRegistry.addSvgIcon("checkStep",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/checkStep.svg"),
    );

    this.matIconRegistry.addSvgIcon("previewStep",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/previewStep.svg"),
    );

    this.matIconRegistry.addSvgIcon("ON_TIME",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/slaOnTime.svg"),
    );

    this.matIconRegistry.addSvgIcon("EXPIRED",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/slaExpired.svg"),
    );

    this.matIconRegistry.addSvgIcon("ABOUT_EXPIRE",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/slaAboutExpire.svg"),
    );


    this.matIconRegistry.addSvgIcon("ariaWarn",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Warn.svg"),
    );

    this.matIconRegistry.addSvgIcon("CHANGE_REQUEST",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=C&D.svg"),
    );

    this.matIconRegistry.addSvgIcon("RETURN_REQUEST",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=C&D.svg"),
    );

    this.matIconRegistry.addSvgIcon("RETURN_REQUEST_SELLER",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=C&D.svg"),
    );

    this.matIconRegistry.addSvgIcon("CHANGE_REQUEST_SELLER",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=C&D.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "SERVICE_TECHNICAL",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=Servicio Técnico.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "INDECOPI",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=Indecopi.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "LDR",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=LDR.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "INCIDENCE",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Icon=Incidencia.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaNetworkError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/NetworkError.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaSearch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Search.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/SearchError.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaVip",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Vip.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaDNI",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/ID.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaEmail",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Correo.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "ariaTicket",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Ticket.svg"),
    );
    this.matIconRegistry.addSvgIcon(
      "auth-microsoft",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/auth-microsoft.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/Delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "aria-not-content-search",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-not-content-search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "aria-error-connection",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-error-connection.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "aria-filter-plus",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-filter-plus.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "CRITICAL",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/priority=critical.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "HIGH",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/priority=high.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "LOW",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/priority=low.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "DEFAULT",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/priority=low.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ON_TIME",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/sla=on_time.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "REGULAR",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/sla=regular.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "OUT_OF_TIME",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/sla=out_of_time.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ariaTicketNumber",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-ticket-number.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ariaDNISearch",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-dni-search.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ariaOrderId",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-order-id.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ariaSaleNote",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-sale-note.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "formSearchError",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/aria-search-error.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "circle",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/circle.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "confirmation-delete",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/confirmation-delete.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "email-send",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/email-send.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "excel-csv",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/excel_csv.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "help",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/help.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "playlist-add",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/playlist_add.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "search-form",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/search_form.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "ariaNotification",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/notification_bell.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "generic-send",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/generic_send.svg")
    );
    this.matIconRegistry.addSvgIcon(
      "email-send",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/icons/send.svg")
    );
  }
}
