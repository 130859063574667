import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'defaultValue'
})
export class DefaultValuePipe implements PipeTransform {
    transform(value: any, defaultValue: any = '-'): any {
        if (value === null || value === undefined || value === '' || (typeof value === 'string' && value.trim() === '')) {
            return defaultValue;
        }
        return value;
    }
}
