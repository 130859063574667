import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarContentComponent } from '@app/common-components/snackbar-content/snackbar-content.component';
import { environment } from '@environment/environment';

export type AriaToastPosition =
| 'top'
| 'top-start'
| 'top-end'
| 'top-left'
| 'top-right'
| 'center'
| 'center-start'
| 'center-end'
| 'center-left'
| 'center-right'
| 'bottom'
| 'bottom-start'
| 'bottom-end'
| 'bottom-left'
| 'bottom-right';

const positionMappings = new Map<AriaToastPosition, MatSnackBarConfig>([
  ['top', { horizontalPosition: 'start' }],
  ['top-start', { horizontalPosition: 'start', verticalPosition: 'top'}],
  ['top-end', { horizontalPosition: 'end', verticalPosition: 'top' }],
  ['top-left', { horizontalPosition: 'left', verticalPosition: 'top' }],
  ['top-right', { horizontalPosition: 'right', verticalPosition: 'top' }],
  //Fixme: mat snack bar no tiene configuraciones para considerar posición vertical: center
  ['center', { horizontalPosition: 'center' }],
  ['center-start', { horizontalPosition: 'center' }],
  ['center-end', { horizontalPosition: 'center' }],
  ['center-left', { horizontalPosition: 'center' }],
  ['center-right', { horizontalPosition: 'center' }],

  ['bottom', {  verticalPosition: 'bottom', horizontalPosition: 'center'  }],
  ['bottom-start', { horizontalPosition: 'start', verticalPosition: 'bottom'  }],
  ['bottom-end', { horizontalPosition: 'end', verticalPosition: 'bottom'  }],
  ['bottom-left', { horizontalPosition: 'left', verticalPosition: 'bottom'  }],
  ['bottom-right', { horizontalPosition: 'right', verticalPosition: 'bottom' }],
])
export class AriaToastrInformationService {

  constructor(
    private _snackBar: MatSnackBar
  ) { }

  show(
    title: string,
    message: string,
    iconName: string,
    typeToastr: string,
    position: AriaToastPosition,
    defaultColor?: boolean,
    withBtnClose?: boolean,
    withBtnAccept?: boolean
  ) {
    let baseConfiguration = positionMappings.get(position) || {  horizontalPosition: 'center', verticalPosition: 'bottom'};
    let snackbarFinalConfig = Object.assign(baseConfiguration, {
      panelClass: `snackbar-${typeToastr}`,
      duration: (withBtnClose || withBtnAccept) ? 0 : environment.timeToastr,
      data: {
        title,
        message,
        icon: iconName,
        defaultColor: defaultColor,
        withBtnClose: withBtnClose,
        withBtnAccept: withBtnAccept
      }
    });
    this._snackBar.openFromComponent(SnackbarContentComponent, snackbarFinalConfig);
  }

  showInfo(title: string, message: string, position: AriaToastPosition, defaultColor?: boolean, withBtnClose?: boolean, withBtnAccept?: boolean) {
    this.show(title, message, 'info', 'info', position, defaultColor, withBtnClose, withBtnAccept);
  }

  showSuccess(title: string, message: string, position: AriaToastPosition, defaultColor?: boolean, withBtnClose?: boolean, withBtnAccept?: boolean) {
    this.show(title, message, 'check_circle', 'success', position, defaultColor, withBtnClose, withBtnAccept);
  }


  showWarning(title: string, message: string, position: AriaToastPosition, defaultColor?: boolean, withBtnClose?: boolean, withBtnAccept?: boolean) {
    this.show(title, message, 'error_outlined', 'warning', position, defaultColor, withBtnClose, withBtnAccept);
  }

  showError(title: string, message: string, position: AriaToastPosition, defaultColor?: boolean, withBtnClose?: boolean, withBtnAccept?: boolean) {
    this.show(title, message, 'error_outlined', 'error', position, defaultColor, withBtnClose, withBtnAccept);
  }
}
