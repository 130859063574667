
export enum CustomerSearchType{
  IDENTITY_DOCUMENT = 'IDENTITY_DOCUMENT',
  EMAIL = 'EMAIL',
  ORDER_ID = 'ORDER_ID',
  SALE_NOTE_NUMBER = 'SALE_NOTE_NUMBER',
  TICKET_NUMBER = 'TICKET_NUMBER'
}

export interface CustomerSearch {
  tenantIds: string[];
  searchValue: string;
  dateFrom: Date;
  dateTo: Date;
  searchType: CustomerSearchType;
  limit?: number;
  offset?:number;
}
