import { NgxLoggerLevel } from 'ngx-logger';
import { commons } from './environment.commons';

export const environment = {
  firebaseKeys: {
    apiKey: "AIzaSyA8pameJ4hr7sAQ3yOYbezTizVfmrhSQUk",
    authDomain: "pe-gcp-customercare-02.firebaseapp.com",
    databaseURL: "https://pe-gcp-customercare-02-default-rtdb.firebaseio.com",
    projectId: "pe-gcp-customercare-02",
    storageBucket: "pe-gcp-customercare-02.appspot.com",
    messagingSenderId: "52415902569",
    appId: "1:52415902569:web:c080b833159bb3d72743f8",
    measurementId: "G-4LHFVBX0C0",
    vapidKey: "BH_-GZkVKh0MiTLmSrUxgaMruW3nRHL7f8AhHje8kJoH3NbvQZ0KEmkHa_I-E7krI7EhXmLovD7nqJ0GmhmjuLg"
  },
  //Local Mode
  //API_ACCOUNT: "http://localhost:8081",
  // API_CATALOG: "http://localhost:8084",
  //API_REPORT: "http://localhost:8080",
  // API_TICKETS: "http://localhost:8084",

  API_AUDIT: "https://cc-auditor-dev.cc.cloudintercorpretail.pe",
  API_TICKETS: "https://aria-tickets-dev.cc.cloudintercorpretail.pe",
  API_REPORT: "https://aria-reports-dev.cc.cloudintercorpretail.pe",
  API_CATALOG: "https://api.cc-irdigital.pe/dev/aria/aria-api-catalog",
  // API_CATALOG: "https://aria-catalog-dev.cc.cloudintercorpretail.pe",
  API_ACCOUNT: "https://api.cc-irdigital.pe/dev/aria/aria-api-accounts",

  CUSTOMER_API: "https://customers-prd.cc.cloudintercorpretail.pe",
  ORDERS_API: "https://api-order-prd.cc.cloudintercorpretail.pe",
  //ORDERS_API: "http://localhost:8080",
  UBIGEO_API: "https://ubigeo-prd.cc.cloudintercorpretail.pe",
  uploadBucket: 'pe-indigitalxp-aria-files-dev',
  notificationFirestore: 'notificationsARIADev',
  tokenFCM: 'ariaTokenFCMDev',
  numItemsPerPage : 10,
  commons,
  maxFileSizeMB: +50,
  timeToastr: 6000,
  loggerLevel: NgxLoggerLevel.DEBUG,
  production: false
};
