import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { getAuth, provideAuth } from '@angular/fire/auth';
import { LoggerModule } from 'ngx-logger';
import { environment } from '@environment/environment';
import { HTTP_INTERCEPTORS , HttpClientModule } from '@angular/common/http';
import { DEFAULT_TIMEOUT, TimeoutInterceptor } from './utils/util/timeout.interceptor';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AriaToastrInformationService } from './services/toastr/aria-toastr-information.service';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { MatDialogModule } from '@angular/material/dialog';
import { ServiceWorkerModule } from '@angular/service-worker';
import { DatePipe } from '@angular/common';
import { DefaultValuePipe } from './utils/pipe/default-value.pipe';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule,
    MatDialogModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseKeys)),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideMessaging(() => getMessaging()),
    LoggerModule.forRoot({ level: environment.loggerLevel }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    [{ provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true }],
    { provide: FIREBASE_OPTIONS, useValue: environment.firebaseKeys },
    // { provide: 'messaging', useValue: messaging },
    {
      provide: 'AriaToastrInformationService',
      useFactory: (snackBar: MatSnackBar) => {
        return new AriaToastrInformationService(snackBar);
      },
      deps: [MatSnackBar]
    },
    { provide: DEFAULT_TIMEOUT, useValue: 10000 },
    DatePipe,
    DefaultValuePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
