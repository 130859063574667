import { Component, Inject, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';

export interface SnackBarData {
  title: string;
  message: string;
  icon: string;
  defaultColor?: boolean;
  withBtnClose?: boolean;
  withBtnAccept?: boolean;
}

@Component({
  selector: 'app-snackbar-content',
  standalone: true,
  imports: [
    CommonModule,
    MatSnackBarModule,
    MatButtonModule,
    MatIconModule
  ],
  template: `<div>
    <div class="row m-0 gap-10">
      <div class="col-sm-auto p-0">
        <mat-icon [class.material-symbols-outlined]="data.icon=='check_circle'">{{data.icon}}</mat-icon>
      </div>
      <div class="col-sm p-0">
        <div class="row m-0 gap-10 justify-content-between">
          <!--Esconder titulo por el momento
          <div class="col-sm-auto p-0">
            <div [class]="'toast-title toast-title'+((data.defaultColor!=undefined && data.defaultColor)?'-default':'')">
              <b>{{data.title}}</b>
            </div>
          </div>-->
          <div class="col-sm-auto p-0" *ngIf="data.withBtnClose">
            <a href="javascript:void(0)" (click)="snackBarRef.dismissWithAction()"><mat-icon>close</mat-icon></a>
          </div>
        </div>
        <div [class]="'toast-text'+((data.defaultColor!=undefined && data.defaultColor)?'-default':'')" [innerHTML]="data.message"></div><!--{{data.message}}-->
      </div>
    </div>
    <div class="row gap-0" *ngIf="data.withBtnAccept">
      <span class="actions" matSnackBarActions>
        <button mat-flat-button matSnackBarAction (click)="snackBarRef.dismissWithAction()">Aceptar</button>
      </span>
    </div>
  </div>`,
  styleUrls: ['./snackbar-content.component.scss']
})
export class SnackbarContentComponent {
  snackBarRef = inject(MatSnackBarRef);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackBarData) { }
}
